import Vue from "vue";
import VueRouter from "vue-router";
import quiz from "../views/Quiz.vue"
import success from "../views/Success.vue"
import introSection from "../views/IntroSection.vue"
import dragDrop from "../views/DragDrop.vue"


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Intro",
    component: () =>
      import(/* webpackChunkName: "intro" */ "../views/Intro.vue"),
  },
  {
    path: "/menu",
    name: "Menu",
    component: () =>
      import(/* webpackChunkName: "Menu" */ "../views/Menu.vue"),
  },
  {
    path: "/inbound/page-1-3",
    name: "Inbound 1.3",
    component: introSection,
  },
  {
    path: "/inbound/page-1-4",
    name: "Inbound 1.4",
    component: () =>
      import(/* webpackChunkName: "Tank" */ "../views/inbound/game.vue"),
  },
  {
    path: "/inbound/page-1-5",
    name: "Inbound 1.5",
    component: success,
  },
  {
    path: "/formulation/page-2-2",
    name: "Formulation 2.2",
    component: introSection,
  },
  {
    path: "/formulation/page-2-3",
    name: "Formulation 2.3",
    component: dragDrop
  },
  {
    path: "/formulation/page-2-4",
    name: "Formulation 2.4",
    component: success,
  },
  {
    path: "/qc/page-3-2",
    name: "QC 3.2",
    component: introSection,
  },
  {
    path: "/qc/page-3-3",
    name: "QC 3.3",
    component: quiz,
  },
  {
    path: "/qc/page-3-4",
    name: "QC 3.4",
    component: quiz,

  },
  {
    path: "/qc/page-3-5",
    name: "QC 3.5",
    component: quiz,
  },
  {
    path: "/qc/page-3-6",
    name: "QC 3.6",
    component: success,
  },
  {
    path: "/packing/page-4-2",
    name: "Packing 4.2",
    component: introSection,
  },
  {
    path: "/packing/page-4-3",
    name: "Packing 4.3",
    component: quiz,
  },
  {
    path: "/packing/page-4-4",
    name: "Packing 4.4",
    component: quiz,
  },
  {
    path: "/packing/page-4-5",
    name: "Packing 4.5",
    component: quiz,
  },
  {
    path: "/packing/page-4-6",
    name: "Packing 4.6",
    component: success,
  },
  {
    path: "/outbound/page-5-2",
    name: "QC 5.2",
    component: introSection,
  },
  {
    path: "/outbound/page-5-3",
    name: "QC 5.3",
    component: () =>
    import(/* webpackChunkName: "intro" */ "../views/MultipleQuiz.vue"),
  },
  {
    path: "/outbound/page-5-4",
    name: "QC 5.4",
    component: quiz,
  },
  {
    path: "/outbound/page-5-5",
    name: "QC 5.5",
    component: quiz,
  },
  {
    path: "/outbound/page-5-6",
    name: "QC 5.6",
    component: success,
  },
  {
    path: "/challenge/page-6-2",
    name: "challenge 6.2",
    component: dragDrop
  },
  {
    path: "/challenge/page-6-3",
    name: "challenge 6.3",
    component: success
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});




export default router;
