<template>
  <div class="row drag-drop">
    <div class="col-12">
      <section ref="items" class="draggable-items"></section>
      <section ref="pairs" class="matching-pairs"></section>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
export default {
  name: "two-lists",
  display: "Two Lists",
  props: ["game", "value"],
  components: {
    draggable,
  },
  data() {
    return {
      list2: [],
    };
  },
  methods: {},
  mounted() {
    const brands = [
      {
        iconName: "airbnb",
        brandName: "Airbnb",
        color: "#fd5c63",
      },
      {
        iconName: "adobe",
        brandName: "Adobe",
        color: "#ff0000",
      },
      {
        iconName: "amazon",
        brandName: "Amazon",
        color: "#333333",
      },
    ];
    let correct = 0;
    let total = 0;
    const totalDraggableItems = this.game.length;
    const totalMatchingPairs = this.game.length; // Should be <= totalDraggableItems

    const draggableItems = this.$refs["items"];
    const matchingPairs = this.$refs["pairs"];
    let draggableElements;
    let droppableElements;

    const self = this;

    initiateGame();

    function initiateGame() {
      const randomDraggableBrands = self.game.map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);;
      const alphabeticallySortedRandomDroppableBrands = self.game;
      // Create "draggable-items" and append to DOM
      for (let i = 0; i < randomDraggableBrands.length; i++) {
        draggableItems.insertAdjacentHTML(
          "beforeend",
          `
            <div class="icon-item draggable" draggable="true" style="background-image: url('/assets/images/${randomDraggableBrands[i].imagePath}');" id="${randomDraggableBrands[i].name}"></div>
          `
        );
      }

      // Create "matching-pairs" and append to DOM
      for (
        let i = 0;
        i < alphabeticallySortedRandomDroppableBrands.length;
        i++
      ) {
        matchingPairs.insertAdjacentHTML(
          "beforeend",
          `
          <div class="matching-pair">
            <span class="droppable" data-url="${alphabeticallySortedRandomDroppableBrands[i].imagePath}" data-brand="${alphabeticallySortedRandomDroppableBrands[i].name}"></span>
            <span class="label">${alphabeticallySortedRandomDroppableBrands[i].name}</span>
          </div>
          `
        );
      }

      draggableElements = document.querySelectorAll(".draggable");
      droppableElements = document.querySelectorAll(".droppable");

      draggableElements.forEach((elem) => {
        elem.addEventListener("dragstart", dragStart);
      });

      droppableElements.forEach((elem) => {
        elem.addEventListener("dragenter", dragEnter);
        elem.addEventListener("dragover", dragOver);
        elem.addEventListener("dragleave", dragLeave);
        elem.addEventListener("drop", drop);
      });
    }

    // Drag and Drop Functions

    //Events fired on the drag target

    function dragStart(event) {
      event.dataTransfer.setData("text", event.target.id); // or "text/plain"
    }

    //Events fired on the drop target
    function dragEnter(event) {
      if (
        event.target.classList &&
        event.target.classList.contains("droppable") &&
        !event.target.classList.contains("dropped")
      ) {
        event.target.classList.add("droppable-hover");
      }
    }

    function dragOver(event) {
      if (
        event.target.classList &&
        event.target.classList.contains("droppable") &&
        !event.target.classList.contains("dropped")
      ) {
        event.preventDefault();
      }
    }

    function dragLeave(event) {
      if (
        event.target.classList &&
        event.target.classList.contains("droppable") &&
        !event.target.classList.contains("dropped")
      ) {
        event.target.classList.remove("droppable-hover");
      }
    }

    function drop(event) {
      event.preventDefault();
      event.target.classList.remove("droppable-hover");
      const draggableElementBrand = event.dataTransfer.getData("text");
      const droppableElementBrand = event.target.getAttribute("data-brand");
      const droppableElementUrl = event.target.getAttribute("data-url");
      const isCorrectMatching = draggableElementBrand === droppableElementBrand;
      total++;
      if (isCorrectMatching) {
        const draggableElement = document.getElementById(draggableElementBrand);
        event.target.classList.add("dropped");
        draggableElement.classList.add("dragged");
        draggableElement.setAttribute("draggable", "false");

        event.target.innerHTML = `<div class="icon-item" style="background-image: url('/assets/images/${droppableElementUrl}');"></div>`;
        correct++;
      }
      if (correct === Math.min(totalMatchingPairs, totalDraggableItems)) {
        // Game Over!!
        setTimeout(() => {}, 200);
          self.$emit('input', true)
      }
    }
  },
};
</script>