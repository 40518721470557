<template>
  <div class="drag-drop-page">
    <div class="container">
      <div class="row align-items-center pt-3">
        <div class="col-12 col-lg-6 pt-3 pt-lg-0 text-center text-lg-start">
          <h1 ref="title" class="heading" v-html="pageTitle" />
        </div>
        <div class="col-12 col-lg-6 text-end">
          <VoiceHelper
            v-if="voiceOverPath.length > 0"
            :videoPath="voiceOverPath"
          />
        </div>
      </div>
      <div class="row justify-content-center align-items-center mid-row">
        <div class="col-11 col-lg-9">
          <div class="text-container p-3">
            <h2 class="text-left">{{heading}}</h2>
            <div class="text-left content" v-html="body" />
            <DragDrop :game="gameData" v-model="gameCompleted"  />
          </div>
        </div>
      </div>
      <div class="row justify-content-between align-items-end bottom-row">
        <div class="col-4 col-lg-1">
          <img class="img-fluid logo" src="/assets/images/logo.png" />
        </div>
        <div class="col-4 col-lg-10">
          <ProgressMenu :url="link" />
        </div>
        <div class="col-4 col-lg-1 text-end">
          <div v-if="gameCompleted" ref="nextButton" class="next-btn-wrapper mb-3 me-3 d-inline-block">
            <router-link :to="link" class="next-btn">Next</router-link>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="col-12 footer-links bg--white">
        © AstraZeneca
        <span>|</span>
        <a href="https://www.astrazeneca.com/legal-notice-and-terms-of-use.html" target="_blank">Legal notice and Terms of Use</a>
        <span>|</span>
        <a href="https://www.azprivacy.astrazeneca.com/europe/unitedkingdom/en/privacy-notices.html" target="_blank">Privacy Notice</a>
        <span>|</span>
        <a href="//policy.cookiereports.com/fe01e9b6-en-gb.html" class="CookieReportsLink">Cookie Policy</a>
        <span>|</span>        
        <a href="https://www.astrazeneca.com/our-company/Contact-us.html" target="_blank">Contact us</a>
      </div>
    </footer>
  </div>
</template>

<script>
import { gsap } from "gsap";
import video from "../components/Video.vue";
import VoiceHelper from "../components/VoiceHelper.vue";
import ProgressMenu from "../components/ProgressMenu";
import DragDrop from "../components/DragDrop.vue"

export default {
  name: "page-1-3",
  components: {
    vimeoPlayer: video,
    VoiceHelper,
    ProgressMenu,
    DragDrop
  },
  data() {
    return {
      gameCompleted: false,
      pageNumber: ''
    }
  },
  computed: {
    pageTitle() {
      return this.$store.getters.content[this.pageNumber].title;
    },
    heading() {
      return this.$store.getters.content[this.pageNumber].heading;
    },
    body() {
      return this.$store.getters.content[this.pageNumber].body;
    },
    gameData() {
      return this.$store.getters.content[this.pageNumber].game;
    },
    voiceOverPath() {
      return this.$store.getters.content[this.pageNumber].voiceOverFileName;
    },
    link() {
      return this.$store.getters.content[this.pageNumber].link;
    },
  },
  created() {
    const n = this.$route.fullPath.lastIndexOf("/");
    this.pageNumber = this.$route.fullPath.substring(n + 1);
  },
  watch: {
    gameCompleted(value) {
      if(value) {
        setTimeout(() => {
          gsap.to(this.$refs.nextButton, { opacity: 1,  y:  -30})
        }, 100);
      }
    }
  }
};
</script>
