<template>
  <div id="app">
      <div class="h-100 w-100">
        <div :key="0" v-if="loading" class="loading">
          <loading />
        </div>
        <div :key="1" v-else class="app-wrapper">
          <vue-page-transition name="fade" class="app-wrapper">
            <router-view :key="$route.fullPath" />
          </vue-page-transition>
        </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import loading from "./components/Loader";
export default {
  components: {
    loading,
  },
  computed: {
    ...mapGetters(["loading"]),
  },
  created() {
    this.$store.dispatch("getContent");
  },
};
</script>

<style lang="scss">
#app,
.app-wrapper {
  height: 100%;
  width: 100%;
}
.loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
