<template>
  <div :class="`${pageNumber} intro-section`">
    <div class="container">
      <div class="row align-items-center pt-3">
        <div class="col-12 col-lg-6 pt-3 pt-lg-0 text-center text-lg-start">
          <h1 ref="title" class="heading" v-html="pageTitle" />
        </div>
        <div class="col-12 col-lg-6 text-end">
          <voiceHelper
            ref="voiceHelper"
            v-if="voiceOverPath.length > 0"
            :videoPath="voiceOverPath"
          />
        </div>
      </div>
      <div class="row justify-content-center align-items-center mid-row">
        <div class="col-11 col-lg-9">
          <vimeoPlayer ref="videoPlayer" v-model="videoEnded" :videoId="videoUrl" />
        </div>
      </div>
      <div class="row justify-content-between align-items-end bottom-row">
        <div class="col-4 col-lg-1">
          <img class="img-fluid logo" src="/assets/images/logo.png" />
        </div>
        <div class="col-4 col-lg-10">
          <ProgressMenu :url="link" />
        </div>
        <div class="col-4 col-lg-1 text-end">
          <div v-if="videoEnded" ref="nextButton" class="next-btn-wrapper mb-3 me-3 d-inline-block">
            <router-link :to="link" class="next-btn">Next</router-link>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="col-12 footer-links bg--white">
          © AstraZeneca
          <span>|</span>
          <a href="https://www.astrazeneca.com/legal-notice-and-terms-of-use.html" target="_blank">Legal notice and Terms of Use</a>
          <span>|</span>
          <a href="https://www.azprivacy.astrazeneca.com/europe/unitedkingdom/en/privacy-notices.html" target="_blank">Privacy Notice</a>
          <span>|</span>
          <a href="//policy.cookiereports.com/fe01e9b6-en-gb.html" class="CookieReportsLink">Cookie Policy</a>
          <span>|</span>          
          <a href="https://www.astrazeneca.com/our-company/Contact-us.html" target="_blank">Contact us</a>
      </div>
    </footer>
  </div>
</template>

<script>
import video from "../components/Video.vue";
import voiceHelper from "../components/VoiceHelper.vue";
import { gsap } from "gsap";
import ProgressMenu from "../components/ProgressMenu";

export default {
  name: "IntroSection",
  components: {
    vimeoPlayer: video,
    voiceHelper,
    ProgressMenu
  },
  data() {
    return {
      pageNumber: null,
      videoEnded: false
    };
  },
  computed: {
    pageTitle() {
      return this.$store.getters.content[this.pageNumber].title;
    },
    videoUrl() {
      return this.$store.getters.content[this.pageNumber].videoUrl;
    },
    voiceOverPath() {
      return this.$store.getters.content[this.pageNumber].voiceOverFileName;
    },
    link() {
      return this.$store.getters.content[this.pageNumber].link;
    },
  },
  created() {
    const n = this.$route.fullPath.lastIndexOf("/");
    this.pageNumber = this.$route.fullPath.substring(n + 1);
  },
  mounted() {
    gsap
      .timeline()
      // .fromTo(
      //   this.$refs.title,
      //   { opacity: 0, y: 50 },
      //   { opacity: 1, y: 0, duration: 0.75 }
      // )
      .fromTo(
        this.$refs.videoPlayer.$el,
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 0.75 },
        0.3
      );
    // .fromTo(
    //   this.$refs.voiceHelper.$el,
    //   { opacity: 0, y: 50 },
    //   { opacity: 1, y: 0, duration: 0.75 },
    //   0
    // );
  },
  watch: {
    videoEnded(value) {
      if(value) {
        setTimeout(() => {
          gsap.to(this.$refs.nextButton, { opacity: 1,  y:  -30})
        }, 100);
      }
    }
  }
};

</script>

<style lang="scss" scoped>
.next-btn-wrapper {
  margin-top: -50px;
  opacity: 0;
}
</style>
