<template>
  <div class="text-center">
    <div @click="menuToggle = !menuToggle" class="d-lg-none menu-trigger" :class="{active: menuToggle}">
      <ul class="hamburger" :class="{active: menuToggle}">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
    <div v-if="menuToggle" class="d-lg-none mobile-progress-menu">
      <div
        class="progress-menu__item"
        :class="{'progress-menu__item--active': item.navQuery === currentSection}"
        v-for="item in navigation"
        :key="item.id"
        :ref="item.navQuery"
      >
        <h3>{{item.name}}</h3>
        <span>{{item.subName}}</span>
      </div>
    </div>


    <div class="d-none d-lg-flex progress-menu">
      <div
        class="progress-menu__item"
        :class="{'progress-menu__item--active': item.navQuery === currentSection}"
        v-for="item in navigation"
        :key="item.id"
        :ref="item.navQuery"
      >
        <h3>{{item.name}}</h3>
        <span>{{item.subName}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressMenu',
  props: ['url'],
  data() {
    return {
      menuToggle: false
    }
  },
  computed: {
    navigation() {
      return this.$store.getters.content.menu.navigation;
    },
    currentSection() {
      return this.$route.fullPath.match(/^\/([^/]*)\//)[1]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>