<template>
    <img :class="{'small': small}" src="/assets/images/loader.svg" />
</template>

<script>
export default {
  name: 'loader',
  props: ['small']
};
</script>

<style lang="scss" scoped>
  img {
    display: block;
    max-width: 40px;
    height: auto;
    margin: 0 auto;
  }
</style>