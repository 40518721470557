<template>
  <div :class="`${this.pageNumber} quiz-template`">
    <div class="container">
      <div class="row align-items-center pt-3">
        <div class="col-12 col-lg-6 pt-3 pt-lg-0 text-center text-lg-start">
          <h1 class="heading" v-html="pageTitle" />
        </div>
        <div class="col-12 col-lg-6 text-end">
          <voiceHelper
            v-if="voiceOverPath.length > 0"
            :videoPath="voiceOverPath"
          />
        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-11 col-lg-9">
          <quiz
            :answers="answers"
            :question="question"
            :questionAnswered="questionAnswered"
            @validateAnswer="validateAnswer"
          />
        </div>
      </div>
      <div class="row justify-content-between align-items-end">
        <div class="col-4 col-lg-1">
          <img class="logo" src="/assets/images/logo.png" />
        </div>
        <div class="col-4 col-lg-10">
          <ProgressMenu :url="link" />
        </div>
        <div class="col-4 col-lg-1 text-end">
          <div v-if="questionAnswered" ref="nextButton" class="next-btn-wrapper mb-3 me-3 d-inline-block">
            <router-link :to="link" class="next-btn">Next</router-link>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="col-12 footer-links bg--white">
        © AstraZeneca
        <span>|</span>
        <a href="https://www.astrazeneca.com/legal-notice-and-terms-of-use.html" target="_blank">Legal notice and Terms of Use</a>
        <span>|</span>
        <a href="https://www.azprivacy.astrazeneca.com/europe/unitedkingdom/en/privacy-notices.html" target="_blank">Privacy Notice</a>
        <span>|</span>
        <a href="//policy.cookiereports.com/fe01e9b6-en-gb.html" class="CookieReportsLink">Cookie Policy</a>
        <span>|</span>        
        <a href="https://www.astrazeneca.com/our-company/Contact-us.html" target="_blank">Contact us</a>
      </div>
    </footer>
  </div>
</template>

<script>
import { gsap } from "gsap";
import video from "../components/Video.vue";
import voiceHelper from "../components/VoiceHelper.vue";
import quiz from "../components/Quiz.vue";
import ProgressMenu from "../components/ProgressMenu";

export default {
  name: "QuizPage",
  components: {
    vimeoPlayer: video,
    ProgressMenu,
    voiceHelper,
    quiz,
  },
  data() {
    return {
      pageNumber: null,
      questionAnswered: false,
    };
  },
  methods: {
    validateAnswer(answer) {
      this.questionAnswered = answer;
    },
  },
  computed: {
    pageTitle() {
      return this.$store.getters.content[this.pageNumber].title;
    },
    link() {
      return this.$store.getters.content[this.pageNumber].link;
    },
    voiceOverPath() {
      return this.$store.getters.content[this.pageNumber].voiceOverFileName;
    },
    question() {
      return this.$store.getters.content[this.pageNumber].quiz.question;
    },
    answers() {
      return this.$store.getters.content[this.pageNumber].quiz.answers;
    },
  },
  created() {
    const n = this.$route.fullPath.lastIndexOf("/");
    this.pageNumber = this.$route.fullPath.substring(n + 1);
  },
  watch: {
    questionAnswered(value) {
      if(value) {
        setTimeout(() => {
          gsap.to(this.$refs.nextButton, { opacity: 1,  y:  0})
        }, 100);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.next-btn-wrapper {
  margin-top: -50px;
  opacity: 0;
}
</style>
