<template>
  <div class="h-100 d-flex align-items-center">
    <div class="video-bg" :class="{ 'video-bg--active': fullscreen }"></div>
    <div class="video-wrapper" :class="{ fullscreen: fullscreen }">
      <vimeo-player
        ref="player"
        :video-id="videoId"
        @ready="onReady"
        :options="options"
        :controls="true"
        class="embed-container"
      ></vimeo-player>

      <div v-if="!videoPlaying" @click="play()" class="controls">
        <div class="controls__play" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "videoPlayer",
  props: ["videoId", "toggleInput", "videoEnded"],
  data() {
    return {
      showInitPlay: true,
      options: {
        muted: false,
        autoplay: false,
        responsive: true,
        dnt: true
      },
      playerReady: false,
      videoPlaying: false,
      fullscreen: false,
      showControls: true,
      timer: null,
    };
  },
  mounted() {
    let self = this;
    this.$refs.player.player.ready().then(function () {
      self.$refs.player.player.element.removeAttribute("title");
    });
    this.$refs.player.player.on("ended", function () {
      if (self.fullscreen) {
        self.toggleFullScreen();
      }
      self.$emit("toggleInput");
      this.value = true;
      self.$emit("input", this.value);

      self.stop();
    });
  },
  created: function () {
    window.addEventListener("mousemove", this.toggleControls);
  },

  destroyed: function () {
    window.removeEventListener("mousemove", this.toggleControls);
  },
  methods: {
    onReady() {
      this.playerReady = true;
    },
    play() {
      // if (!this.fullscreen) {
      //   this.toggleFullScreen();
      // }
      this.videoPlaying = true;
      this.showInitPlay = false;
      this.$refs.player.play();
    },
    pause() {
      this.videoPlaying = false;
      this.$refs.player.pause();
    },
    stop() {
      this.videoPlaying = false;
      this.$refs.player.stop();
    },
    toggleFullScreen() {
      this.fullscreen = !this.fullscreen;
    },
    toggleControls() {
      clearTimeout(this.timer);
      this.showControls = true;
      this.timer = setTimeout(() => {
        this.showControls = false;
      }, 750);
    },
  },
};
</script>
