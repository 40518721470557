import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VuePageTransition from 'vue-page-transition'
Vue.use(VuePageTransition)

import Fake3dImageEffect from '@luxdamore/vue-fake3d-image-effect';
import '@luxdamore/vue-fake3d-image-effect/dist/Fake3dImageEffect.css';
Vue.use(
  Fake3dImageEffect
);

import vueVimeoPlayer from 'vue-vimeo-player'
Vue.use(vueVimeoPlayer)

Vue.config.productionTip = false;

import "./styles/styles.scss";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
