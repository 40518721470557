<template>
  <div class="video-wrapper">
    <div ref="text" class="helper-text">
      Hit the button and hear from Stella
    </div>
    <div ref="videoHelper" class="video-helper" @mouseover="hovered = true" @mouseleave="hovered = false">
      <template v-if="!isPlaying || paused">
        <div class="video-overlay" />
        <div class="play-btn-1" @click="play" />
        <div class="play-btn-2" @click="play" />
      </template>
      <template v-if="isPlaying && hovered">
        <div class="pause-btn" @click="pause" />
      </template>
      <video ref="video" :src="videoUrl" @click="play" @ended="onEnd()"></video>
    </div>
  </div>

</template>

<script>
import { gsap } from "gsap";
export default {
  name: "VoiceHelper",
  props: {
    videoPath: String,
  },
  data() {
    return {
      hovered: false,
      isPlaying: false,
      paused: false,
    };
  },
  computed: {
    videoUrl() {
      return "/assets/video/" + this.videoPath;
    },
  },
  methods: {
    play() {
      this.$refs.video.play();
      this.paused = false;
      this.isPlaying = true;
    },
    pause() {
      this.$refs.video.pause();
      this.isPlaying = false;
      this.paused = true;
    },
    onEnd() {
      this.isPlaying = false;
    },
  },
  mounted() {
    gsap
      .timeline()
      .to(this.$refs.text, { opacity: 1, x:0, duration: 0.5, delay: 0.5 })

  },
};
</script>

<style lang="scss">
.video-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;

  span {
    margin-right: 15px;
    text-align: right;
    padding: 10px;
    font-size: 14px;
    color: #000000;
    text-shadow: 0 0 3px rgba(255,255,255, 0.5);
    animation: blink 2s linear 3 forwards;
  }
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}
.video-helper {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: inline-block;
  transform: scale(.75) translate3d(0, 0, 0);
}

video {
  position: absolute;
  top: -99px;
  left: -234px;
  width: 568px;
  height: 323px;
  z-index: 1;
}
.video-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 2;
  background: rgba(255, 255, 255, 0.85);
  border: 0 solid $primary;
  border-radius: 50%;
}
.play-btn-1 {
  position: absolute;
  top: 0;
  left: 0;
  background: url('/assets/images/play-btn-1.png');
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 4;
  box-sizing: border-box;
  z-index: 4;
}
.play-btn-2 {
  animation: blink 2s linear 3;
  position: absolute;
  top: 0;
  left: 0;
  background: url('/assets/images/play-btn-2.png');
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 5;
  box-sizing: border-box;
  z-index: 4;
}
.pause-btn {
  position: absolute;
  top: 0;
  left: 0;
  background: url('/assets/images/pause-btn.png');
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 5;
  box-sizing: border-box;
  z-index: 4;
}
.helper-text {
  background: $primary;
  color: $white;
  padding: 5px 15px;
  border-radius: 15px;
  transform: translateX(75px);
  opacity: 0;
}
</style>