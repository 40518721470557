import axios from 'axios'

export default {

  getContent(context) {
    context.commit('loading', true)
    axios.get('/content.json')
      .then((res) => {
        context.commit('setContent', res.data)
        console.log(res.data)
        context.commit('loading', false)
      }).catch((err) => {
        console.log(err)
        context.cosmmit('loading', false)
      })
  },


}
