<template>
  <div
    :class="`text-container question ${
      answers.length > 3 || question.length > 100 ? 'question--small' : ''
    } p-4`"
  >
    <h3 ref="question">{{ question }}</h3>
    <div v-if="loading" class="overlay"></div>

    <div
      ref="answers"
      v-for="answer in answers"
      @click="validate(answer.key)"
      :key="answer.key"
      class="question__answer"
      :class="`${selected == answer.key ? 'selected' : ''}
                  ${
                    questionAnswered
                      ? answer.correct
                        ? 'correct'
                        : 'incorrect'
                      : ''
                  }`"
    >
      <div>
        <span>{{ answer.key }}</span>
      </div>
      <img
        class="indicator"
        v-show="questionAnswered"
        :src="`/assets/images/${
          answer.correct ? 'question-tick.png' : 'question-cross.png'
        }`"
      />
      {{ answer.text }}
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
  props: ["question", "answers", "questionAnswered"],
  data() {
    return {
      selected: "",
      loading: false,
    };
  },
  methods: {
    validate(key) {
      if (this.questionAnswered) return;
      this.selected = key;
      this.loading = true;
      setTimeout(() => {
        this.selected = "";
        this.loading = false;
        this.$emit("validateAnswer", true);
      }, 1000);
    },
  },
  mounted() {
    gsap
      .timeline()
      .to(this.$refs.question, { opacity: 1, delay: 0.5, duration: 0.5 })
      .to(this.$refs.answers, {
        opacity: 1,
        delay: 0.2,
        duration: 1,
        stagger: 0.2,
      });
  },
};
</script>

<style>
</style>