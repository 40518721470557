var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`text-container question ${
    _vm.answers.length > 3 || _vm.question.length > 100 ? 'question--small' : ''
  } p-4`},[_c('h3',{ref:"question"},[_vm._v(_vm._s(_vm.question))]),(_vm.loading)?_c('div',{staticClass:"overlay"}):_vm._e(),_vm._l((_vm.answers),function(answer){return _c('div',{key:answer.key,ref:"answers",refInFor:true,staticClass:"question__answer",class:`${_vm.selected == answer.key ? 'selected' : ''}
                ${
                  _vm.questionAnswered
                    ? answer.correct
                      ? 'correct'
                      : 'incorrect'
                    : ''
                }`,on:{"click":function($event){return _vm.validate(answer.key)}}},[_c('div',[_c('span',[_vm._v(_vm._s(answer.key))])]),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.questionAnswered),expression:"questionAnswered"}],staticClass:"indicator",attrs:{"src":`/assets/images/${
        answer.correct ? 'question-tick.png' : 'question-cross.png'
      }`}}),_vm._v(" "+_vm._s(answer.text)+" ")])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }